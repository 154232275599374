import "./styles.scss"

import React from "react"
import CountUp from "react-countup"
import { Typewriter } from "react-simple-typewriter"
import VisibilitySensor from "react-visibility-sensor"

const ShapeCounter = ({ number, description }) => {
  return (
    <div className="shape-counter">
      <div className="shape-counter__wrapper">
        <div className="shape-counter__item">
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <>
                <strong>
                  {isVisible ? <CountUp end={number} /> : <span>0</span>}
                </strong>
                <span className="shape-counter__text">
                  {isVisible ? (
                    <Typewriter
                      words={[description]}
                      loop={1}
                      cursorStyle="|"
                      typeSpeed={70}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    />
                  ) : null}
                </span>
              </>
            )}
          </VisibilitySensor>
        </div>
      </div>
    </div>
  )
}

export default ShapeCounter
