import "./styles.scss"

import React from "react"

import Content from "components/Content"

const Market = () => {
  return (
    <section className="investor-market">
      <div className="container-fluid">
        <h3 className="investor-market__heading">Zalety rynku</h3>
      </div>
      <Content
        type="div"
        image={
          require("assets/images/investor/investor-market-image-1.jpg").default
        }
        hasShape
      >
        <h3>W Krakowie zarabia się najwiecej</h3>
        <p>
          <strong>
            Średnie wynagrodzenie w sektorze przedsiębiorstw w marcu 2023
            wynosiło w Krakowie 10&nbsp;051 zł brutto (wzrost o 13,2 proc. rok do
            roku), tym samym stolica Małopolski jako pierwsze miasto w Polsce
            przekroczyła granicę 10 tys. zł.
          </strong>{" "}
          i jest miastem z najwyższym średnim wynagrodzeniem. To o ponad 2400 zł
          więcej niż średnia dla całej Małopolski (średnie wynagrodzenie na
          czerwiec 2023 wynosiło wg. GUS 7645,76 zł brutto).
        </p>
        <p>
          Tak korzystna sytuacja ekonomiczna mieszkańców miasta sprawia, że to
          jedna z najbardziej atrakcyjnych pod kątem życia lokalizacji na mapie
          Polski. Polepszająca się jakość życia mieszkańców wpływa na rosnący
          popyt na inwestycje mieszkaniowe.
        </p>
        <p>
          <small>Źródło: GUS</small>
        </p>
      </Content>
      <Content
        type="div"
        image={
          require("assets/images/investor/investor-market-image-2.jpg").default
        }
        isReverse
      >
        <h3>Coraz więcej mieszkań oddanych do użytku w Krakowie</h3>
        <p>
          Według danych opracowanych przez Główny Urząd Statystyczny, w 2022
          roku w Krakowie oddano do użytkowania 10 766 mieszkań, czyli o 6,6 %
          więcej niż w roku 2021.
        </p>
        <p>
          W pierwszych siedmiu miesiącach tego roku oddanych do użytkowania
          zostało 126,4 tys. mieszkań.
        </p>
        <p>
          <small>Źródło: GUS</small>
        </p>
      </Content>
      <Content
        image={
          require("assets/images/investor/investor-market-image-3.jpg").default
        }
        type="div"
      >
        <h3>Kraków w europejskim TOP 10</h3>
        <p>
          Według rankingu opublikowanego na łamach serwisu Booking.com,{" "}
          <strong>
            Kraków znajduje się w pierwszej dziesiątce europejskich miast
            najwyżej ocenianych przez turystów za kulturę, historię oraz ofertę
            gastronomiczną.
          </strong>{" "}
          Tak dobre opinie najbardziej przekonują turystów z Niemiec, Stanów
          Zjednoczonych, Wielkiej Brytanii, Słowacji, Izraela oraz Włoch, którzy
          najliczniej odwiedzają stolicę Małopolski. Dzięki dynamicznie
          poszerzanej ofercie połączeń lotniska w Balicach rośnie również liczba
          odwiedzających z krajów arabskich, co z kolei pozwala spodziewać się
          kolejnych wzrostów ruchu turystycznego w Krakowie.
        </p>
        <p>
          <strong>Ile Kraków zarabia na turystach?</strong>
        </p>
        <p>
          Z danych za rok 2022 wynika, że średnio turysta zagraniczny zostawia
          1810 złotych, a krajowy 539 złotych. W 2022 roku wpływy Małopolski z
          turystyki wyniosły nieco ponad 11 miliardów złotych. W tym roku władze
          regionu szacują, że będzie to więcej niż w rekordowym 2019 roku, kiedy
          wyniosły 15 miliardów złotych.
        </p>
        <p>
          <small>Żródło: Booking.com</small>
        </p>
      </Content>
    </section>
  )
}

export default Market
