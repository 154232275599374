import "./styles.scss"

import React from "react"

const Banner = () => {
  return (
    <section
      className="investor-banner"
      style={{
        backgroundImage: `url(${
          require("assets/images/investor/investor-banner-image.jpg").default
        })`,
      }}
    ></section>
  )
}

export default Banner
