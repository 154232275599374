import "./styles.scss"

import React from "react"

const Map = () => {
  const data = [
    {
      title: "Dzielnica I Stare Miasto",
      price: "18 275",
      color: "#AF8550",
    },
    {
      title: "Dzielnica II Grzegórzki",
      price: "18 177",
      color: "#17083B",
    },
    {
      title: "Dzielnica VII Zwierzyniec",
      price: "17 269",
      color: "#0C4553",
    },
    {
      title: "Dzielnica V Krowodrza",
      price: "16 363",
      color: "#083B10",
    },
    {
      title: "Dzielnica XIII Podgórze",
      price: "14 376",
      color: "#777877",
    },
  ]

  return (
    <section className="investor-map">
      <div className="container-fluid">
        <h2 className="h4">
          Krowodrza w czołówce dzielnic o najwyższej cenie za metr kwadratowy
        </h2>
        <div className="row">
          <div className="col-md-5">
            <p>
              <strong>Średnia cena za m2 w poszczególnych dzielnicach:</strong>
            </p>
            <div className="investor-map__info">
              {data?.map((item, index) => (
                <div key={index} className="investor-map__info-item">
                  <div
                    className="investor-map__info-icon"
                    style={{ background: item.color }}
                  />
                  <div className="investor-map__info-content">
                    <p>{item.title}</p>
                    <p>
                      <strong>{item.price} zł</strong>
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <p>
              <small>
                żródło:{" "}
                <a
                  href="https://rynekpierwotny.pl/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Rynek Pierwotny
                </a>
              </small>
            </p>
          </div>
          <div className="col-md-7">
            <div className="investor-map__image">
              <img
                src={
                  require("assets/images/investor/investor-districts-map.svg")
                    .default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Map
