import "./styles.scss"

import React from "react"

import ShapeCounter from "components/ShapeCounter"

const Population = () => {
  return (
    <section className="investor-population">
      <div className="container-fluid">
        <div className="row align-items-md-center">
          <div className="col-lg-6">
            <div className="investor-population__content">
              <h2 className="h3">
                Liczba ludności w stolicy Małopolski stale rośnie
              </h2>
              <p>
                Kraków to drugie pod względem wielkości i liczby ludności miasto
                w Polsce. Liczba zameldowanych mieszkańców w w grudniu 2022 roku
                wyniosła ponad 803 tys. zaś w całym województwie małopolskim
                mieszka niemal 3,5 mln osób. Według najnowszych danych gęstość
                zaludnienia osiągnęła 2389 os./km². Wzrost liczby mieszkańców to
                ogromny atut, gdyż pociąga to za sobą wzrost inwestycji oraz
                skutkuje dynamicznym rozwojem i postępującą modernizacją miasta.
              </p>
              <p>
                Kraków zdecydowanie wyróżnia się na tle innych miast w kraju.
                Jest to jednocześnie miasto akademickie, biznesowe i
                turystyczne. Przybywającym na studia oferuje atrakcyjne kierunki
                rozwoju, szukającym pracy dobrze płatne stanowiska pracy, a
                turystom liczne atrakcje.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <ShapeCounter
              number={803}
              description="tys zameldowanych mieszkańców"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Population
