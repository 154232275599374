import "./styles.scss"

import React from "react"

const Features = () => {
  return (
    <div className="investor-features">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="investor-features__item">
              <div
                className="investor-features__item-image"
                style={{
                  backgroundImage: `url(${
                    require("assets/images/investor/investor-feature-image-1.jpg")
                      .default
                  })`,
                }}
              />
              <h3>Miasto akademickie</h3>
            </div>
          </div>
          <div className="col-md-4">
            <div className="investor-features__item">
              <div
                className="investor-features__item-image"
                style={{
                  backgroundImage: `url(${
                    require("assets/images/investor/investor-feature-image-2.jpg")
                      .default
                  })`,
                }}
              />
              <h3>Miasto biznesowe</h3>
            </div>
          </div>
          <div className="col-md-4">
            <div className="investor-features__item">
              <div
                className="investor-features__item-image"
                style={{
                  backgroundImage: `url(${
                    require("assets/images/investor/investor-feature-image-3.jpg")
                      .default
                  })`,
                }}
              />
              <h3>Miasto turystyczne</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
