import "./styles.scss"

import React from "react"

import Content from "components/Content"

const District = () => {
  return (
    <section className="investor-district">
      <div className="container-fluid">
        <h3 className="investor-district__heading">Zalety dzielnicy</h3>
      </div>
      <Content
        type="div"
        image={
          require("assets/images/investor/investor-district-image-1.jpg")
            .default
        }
        isReverse
        hasShape
      >
        <h3>Życie pełnią życia</h3>
        <p>
          Na terenie dzielnicy oraz w jej bezpośrednim otoczeniu znajdują się
          liczne trasy rowerowe, miejsca na spacery czy inne aktywności sportowe
          i rekreacyjne.
        </p>
        <p>
          Nie brakuje również oferty rozrywkowej, w szczególności dzięki
          bliskości Rynku Głównego czy świetnego skomunikowania z innymi
          częściami miasta.
        </p>
      </Content>
      <Content
        type="div"
        image={
          require("assets/images/investor/investor-district-image-2.jpg")
            .default
        }
        isLast
      >
        <h3>Design, ergonomia i jakość</h3>
        <p>
          Życie na wysokich obrotach to codzienność wielu z nas, w City Łobzowa
          jesteś w centrum wydarzeń, ale gdy tylko będziesz tego potrzebował
          znajdziesz ciszę oraz wytychnienie w komfortowym apartamencie, w
          strefie chill out na dachu czy też w przystosowanym do relaksu
          ogólnodostępnych ogrodzie.
        </p>
      </Content>
    </section>
  )
}

export default District
