import "./styles.scss"

import React from "react"

const Tourist = () => {
  const data = [
    {
      title: "odwiedzających ogółem",
      color: "#17083B",
    },
    {
      title: "odwiedzający krajowi",
      color: "#AF8550",
    },
    {
      title: "odwiedzający zagraniczni",
      color: "#777877",
    },
  ]

  return (
    <section className="investor-tourist">
      <div className="container-fluid">
        <div className="row align-items-md-center">
          <div className="col-lg-6">
            <div className="investor-tourist__chart">
              <img
                src={
                  require("assets/images/investor/investor-tourist-chart.svg")
                    .default
                }
                alt=""
              />
              <div className="investor-tourist__chart-content">
                <ul className="investor-tourist__legend">
                  {data?.map((item, index) => (
                    <li key={index}>
                      <div
                        className="investor-tourist__legend-icon"
                        style={{ background: item.color }}
                      >
                        <div style={{ background: item.color }} />
                      </div>
                      <p>{item.title}</p>
                    </li>
                  ))}
                </ul>
                <div className="investor-tourist__chart-description">
                  <p>
                    <strong>
                      Szacunkowa liczba gości odwiedzających Kraków w latach
                      2005-2019
                    </strong>
                  </p>
                  <p>
                    <small>
                      Źródło: BIP Miasto Kraków
                      <br />
                      <a
                        href="https://www.bip.krakow.pl/zalaczniki/dokumenty/n/258711/karta"
                        rel="noreferrer"
                        target="_blank"
                      >
                        https://www.bip.krakow.pl/zalaczniki/dokumenty/n/258711/karta
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="investor-tourist__content">
              <h2 className="h4">Rekordowy wzrost liczby turystów</h2>
              <div className="investor-tourist__content-block">
                <h3>8,4 mln</h3>
                <p>łączna liczba turystów w 2022 roku</p>
              </div>
              <p>
                W 2022 roku Kraków odwiedziło łącznie ponad 8,4 mln osób. W
                czerwcu 2023 liczba turystów zagranicznych odwiedzających Kraków
                wzrosła o 24,5 procent rok do roku, ale absolutnie rekordowy
                okazał się lipiec, w którym to odnotowano rekordowy wzrost
                wynoszący aż 150 procent więcej turystów zagranicznych niż w
                lipcu 2022 (55 339 vs 138 418).
              </p>
              <p>
                <small>
                  Źródło: Polska Agencja Prasowa, Forum Turystyki, Małopolska
                  Agencja Turystyczna
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tourist
